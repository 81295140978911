@import '../../../../../../styles/shared';

.ctaButton {
  margin-top: 24px;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}

.playerWrapper {
  margin-bottom: 16px;

  @include mq($from: desktop) {
    padding-right: 30px;
    margin-bottom: 0;
  }
}

.playerCenterWrapper {
  margin: 12px 0;

  span {
    font-size: 96px;
  }

  &:hover {
    span {
      font-size: 106px;
    }
  }

  @include mq($from: desktop) {
    margin: 44px 100px 0 100px;
  }
}

.rightPadding {
  @include mq($from: desktop) {
    padding-right: 30px;
  }
}

.leftPadding {
  @include mq($from: desktop) {
    padding-left: 30px;
  }
}

.paragraphTop {
  margin-top: 12px;

  @include mq($from: desktop) {
    margin-top: 44px;
  }
}

// video width = PAGE
.playerPageWidthCenterWrapper {
  @include mq($from: desktop) {
    margin: 44px 0 0 0;
  }
}

.pageWidthText {
  text-align: center;
}

.videoPlayer {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  min-height: 0;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  height: auto;
  max-height: 100%;
  border-radius: 20px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
