.wrapper {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    border-radius: 40px;
  }
}

.holder {
  display: block;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
}

.playWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 56px;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    transition: font-size 100ms ease;
  }

  &:hover {
    span {
      font-size: 66px;
    }
  }
}

.icon {
  color: #fff;
}
